// Controller pour dupliquer un template
// Voir views/users/user_activities/forms/_mon_projet_professionnel.html.slim

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "template", "destination" ]

  addItem(event) {
    event.preventDefault()
    console.log(`templateIndex : ${this.templateIndex}`)

    const regex1 = new RegExp(this.templateIndexMapping + 1,"g");
    const regex2 = new RegExp(this.templateIndexMapping,"g");

    const content = this.templateTarget.innerHTML
      .replace( regex1, this.templateIndex+1)
      .replace( regex2, this.templateIndex)
    this.destinationTarget.insertAdjacentHTML( 'beforeend', content );
    this.templateIndex++
  }

  removeItem(event) {
    console.log("removeItem");
    event.preventDefault()
    if (event.target.dataset.confirm) {
      this.removeItemAfterConfirm(event)
    } else { 
      this.removeItemRightNow(event)
    }
  }

  removeItemAfterConfirm(event) {
    const result = confirm( event.target.dataset.confirm );
    if ( result ) {
      this.removeItemRightNow(event)
    }
  }

  removeItemRightNow(event) {
    const inputBlockToRemove = event.target.closest(`[data-target='${this.identifier}.item']`)
    inputBlockToRemove.remove()
  }

  get templateIndex() {
    return parseInt(this.data.get("template-index"));
  }

  get templateIndexMapping() {
    return parseInt(this.data.get("template-index-mapping"))
  }

  set templateIndex(value) {
    this.data.set("template-index", value);
  }

}