// Controller des dimensions de l'activité Roue des savoir-être

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "index", "block", "previousButton", "nextButton" ]

  showCurrentQuestion(event) {
    // display current block
    this.blockTargets.forEach((el, i) => {
      el.classList.toggle("d-block", this.index == i)
    })
    this.refreshDisplay()

    this.manageNavigation()
    this.manageCompletion()
  }

  next() {
    this.index++
  }

  previous() {
    this.index--
  }

  reinit() {
    this.index = 0;
    return false;
  }

  get index() {
    return parseInt(this.data.get("index"))
  }

  set index(value) {
    this.data.set("index", value)
    this.showCurrentQuestion()
  }

  get currentBlock() {
    return this.blockTargets[this.index]
  }

  isCompleted() {
    return (this.index+1) == this.blockTargets.length
  }

  // Methods that may by overriden ============================

  manageNavigation() {
    // Manage navigation button display
    if (this.index == 0 || this.isCompleted() ) {
      this.previousButtonTarget.classList.add('d-none')
    } else {
      this.previousButtonTarget.classList.remove('d-none')
    }
    this.nextButtonTarget.classList.toggle('d-none', this.isCompleted())
    // Manage next button text
    if ((this.index+2) == this.blockTargets.length) {
      this.nextButtonTarget.textContent = "Valider"
    } else {
      this.nextButtonTarget.textContent = "Suivant"
    }
  }

  refreshDisplay() {
    this.refreshIndexDisplay()
  }

  refreshIndexDisplay() {
    if (this.index + 1 != this.blockTargets.length) {
      this.indexTarget.textContent = this.index + 1 
    }
  }

  manageCompletion() {
    if (this.isCompleted()) {
      let customEvent = new CustomEvent('questions-completed', { bubbles: true });
      this.element.dispatchEvent(customEvent)
    }
  }

  // Utilities

  disableElement(element) {
    element.classList.add('disabled')
    element.classList.add('btn-fantom')
    element.classList.remove('btn-success')
    element.disabled = true
  }

  enableElement(element) {
    element.classList.remove('disabled')
    element.classList.remove('btn-fantom')
    element.classList.add('btn-success')
    element.disabled = false
  }

  initialize() {
    this.showCurrentQuestion(this.index)
  }


  connect() {
    console.log("Question slider connect")
  }
}